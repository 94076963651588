import type { Article, TransformedArticle } from '~/types/Article'
import { API_ARTICLE } from '~/utils/constants'

export default function useArticles(
  initialPageSize: number = 1,
  category: string,
  tag: string
) {
  const localePath = useLocalePath()
  const { locale } = useI18n()

  const pageSize = ref(initialPageSize)
  const isLoadingMore = ref(false)
  const articles = ref<TransformedArticle[]>([])

  // mapping
  const transformArticle = (article: Article): TransformedArticle => ({
    ...article,
    link: localePath(`/articles/${article.slug}`),
    cover: article.ogImg?.formats?.medium.url ?? '',
    alt: article.ogImg?.alternativeText ?? '',
    video: article.video?.url ?? null,
  })

  const fetchArticles = async (page: number, category: string, tag: string) => {
    const response = await $fetch<{ data: Article[]; meta: any }>(API_ARTICLE, {
      query: {
        page,
        pageSize: pageSize.value,
        locale: locale.value ?? 'en',
        category,
        tag,
      },
    })
    return {
      data: response.data.map(transformArticle),
      meta: response.meta,
    }
  }

  const {
    data: fetchedArticles,
    status,
    error,
  } = useLazyAsyncData('articles', () => fetchArticles(1, category, tag), {
    server: false,
    transform: (response) => {
      articles.value = response.data
      return response
    },
  })

  const loadMore = async () => {
    if (isLoadingMore.value) return

    isLoadingMore.value = true
    const nextPage = Math.ceil(articles.value.length / pageSize.value) + 1
    const result = await fetchArticles(nextPage, category, tag)
    if (result.data) {
      articles.value.push(...result.data)
    }
    isLoadingMore.value = false
  }

  const hasMoreArticles = computed(() => {
    const meta = fetchedArticles.value?.meta?.pagination
    if (!meta) return false

    const totalArticles = meta.total
    const loadedArticles = articles.value.length

    return loadedArticles < totalArticles
  })

  return {
    articles,
    isLoadingMore,
    status,
    error,
    loadMore,
    hasMoreArticles,
  }
}

// landing articles
export const useLandingArticle = (pageSize: number) => {
  const localePath = useLocalePath()
  const { locale } = useI18n()

  const page = ref(1)

  const {
    data: articleResponse,
    status: articleStatus,
    error: articleError,
    execute: fetchArticle,
  } = useLazyFetch(API_ARTICLE, {
    params: {
      page: page.value,
      pageSize,
      locale: locale.value ?? 'en',
    },
  })

  const landingArticles = computed(() =>
    articleResponse.value?.data?.map((article) => ({
      ...article,
      link: localePath(`/articles/${article.slug}`),
      cover: article.ogImg?.formats?.medium.url ?? '',
      alt: article.ogImg?.alternativeText ?? '',
    }))
  )

  return {
    landingArticles,
    articleStatus,
    articleError,
    fetchArticle,
    pageSize,
  }
}
